var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section9"},[_c('div',{staticClass:"s-divider"}),_c('div',{staticClass:"info",attrs:{"data-aos":"fade-up","data-aos-delay":"400"}},[_vm._m(0),_c('div',{staticClass:"divider"}),_c('p',[_vm._v("\n      建築不只精密科學計算，而是有溫度、有情感、有故事的生活聚場，從結構形體昇華至精神領域。璞真願意花更多時間探索生活，量身訂製創新空間提案，以綠建築、智慧建築設計導入低碳思維。當建築擁有獨一無二的性格，自然吸引居住品味相契的人們，回歸生活最樸實且真摯的樣貌。\n    ")]),[(!_vm.isMobile)?_c('div',{staticClass:"swiper-box1 swiper-box",attrs:{"data-aos":"fade-up"}},[_c('swiper',{ref:"swiper1",attrs:{"options":_vm.swiperOptions1},on:{"slideChangeTransitionStart":_vm.onSwiperSlideChangeTransitionStart1}},[_vm._l((_vm.slides1),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
              backgroundImage: ("url(" + (slide.img) + ")"),
            })})}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.activeSlide1.caption)}})],1):_c('div',{staticClass:"swiper-box1 swiper-box",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"caption large"},[_vm._v("璞真建設 經典業績")]),_c('swiper',{ref:"swiper1",attrs:{"options":_vm.swiperOptions1},on:{"slideChangeTransitionStart":_vm.onSwiperSlideChangeTransitionStart1}},[_vm._l((_vm.slides1),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
              backgroundImage: ("url(" + (slide.img) + ")"),
            })})}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.activeSlide1.caption)}})],1)]],2),[(_vm.isMobile)?_c('div',{staticClass:"swiper-box swiper-box2",attrs:{"data-aos":"fade-up"}},[_c('swiper',{ref:"swiper2",attrs:{"options":_vm.swiperOptions2},on:{"slideChangeTransitionStart":_vm.onSwiperSlideChangeTransitionStart2}},[_vm._l((_vm.slides2),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
            backgroundImage: ("url(" + (slide.img) + ")"),
          })})}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.activeSlide2.caption)}})],1):_c('div',{staticClass:"swiper-box",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"caption large"},[_vm._v("璞真建設 經典業績")]),_c('swiper',{ref:"swiper2",attrs:{"options":_vm.swiperOptions2},on:{"slideChangeTransitionStart":_vm.onSwiperSlideChangeTransitionStart2}},[_vm._l((_vm.slides2),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
            backgroundImage: ("url(" + (slide.img) + ")"),
          })})}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.activeSlide2.caption)}})],1)]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_vm._v("\n      建築、自然永恆詩境"),_c('br'),_vm._v("\n      鐫刻城市美學指標\n    ")])}]

export { render, staticRenderFns }