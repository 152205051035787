<template>
  <div class="section1" >
    <div class="intro">
      <div class="i1" data-aos="fade-down" data-aos-delay="1000">
        中山北路 盼到璞真
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" class="i2" viewBox="0 0 536 183">
        <g>
          <g id="A">
            <g id="_x31__1_">
              <defs>
                <path
                  id="SVGID_1_"
                  d="M33.6,90.4c-0.5,0.7-1.7,2.2-2.5,1.4c-1,1.8-2.3,2-4.8,2.3c-0.1-0.3-0.1-0.6-0.5-0.8
					c-0.2,0.5-1,1.3-1.6,1.5c-0.7-0.7-2.2,0.5-3,0.8c-2.1-0.2-4.4-2.3-5.5-3.3c-1.5-1.6-2.1-4.3-3.7-5.9c-1.1-2-1.9-8.6-1.5-11.4
					c0-1.4-0.1-2.8-0.1-4.2c0.7-1.2,1.9-1.9,1.9-3.4c1.8-4.4,6.9-7,10.1-9.9c0.9-1.4-0.2-2.2,0.1-3.7c1.4-3-0.1-7.4-0.3-10.5
					c0.8-0.6,0-2.2,0.1-3.1c1.1-1.8-0.3-3.5-1.3-4.4c-1.3,1.3-3.5,1.4-4.1,3.1c-0.5,0.2-1.2,0.1-1.5,0.8c-0.2-0.2-1.3,0.1-2.2,0.4
					c-1.2,0.4-0.1,1.2-2.5,1.1c-0.3,0.1-1.3-0.4-1.7-0.5c-2-0.4-4.2-3.9-3.5-6.7c2.2-3.6,0-5.1-0.3-8.5c0.5-0.2,1.1-0.5,1.6-0.7
					c1.1,1.3-0.4,2.6,2.2,2.5c0.3,0.2,0.8,0.4,1.3,0.4c0.3-0.4,0.3-0.7,1-0.8c1,0.5,2.3-0.6,3-1c1.4-0.4,3.1-0.6,4-1.9
					c0.2-0.3,0.2-0.9,0.5-1.1c0.3-0.3,0.7-0.1,0.9-0.4c0.1-1.4,1.7-2.6,3-3c4,1.1,4.4-2.4,9.9,1.6c0.8,1.6,4.5,0.7,4.7,3.3
					c-2.9,8.3-2.2,17-2.7,25.6c0.8,1,0.6,6.8,0.5,8.8c4.7-0.1,6.8,6.6,2.8,9.2c-0.3,0.5-0.2,1.3-0.6,1.6c-0.4,0.4-1,0.4-1.3,1
					c0.6,3.6-1.1,11.3-1.3,13.5c-0.7,0.2-0.2,1.2-0.3,1.8C34.2,87.4,33.5,88.5,33.6,90.4z M16.7,88.3c-0.3-0.4-0.9-1.3-1.5-1.5
					c-0.1-1.8,0.6-2.7,0.7-4.2c0.4,0,2-0.9,2.3-1.1c0.5,0.5,0.7,0.9,1.4,1.2c0.1,3-1.7,3.7-2.7,5.6C16.9,88.3,16.8,88.3,16.7,88.3z"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlink:href="#SVGID_1_" style="overflow: visible" />
              </clipPath>
              <path
                id="_x31_"
                class="st0 l01"
                d="M0.1,25.9c9.3,20.4,25.6-2.8,32.2-7.2c-8.3,19.8,1.3,47.7-4.6,67c-2.5,8.2-12.8,10.3-15.5,0.6
				c5.5-8,3.1-27.2,22.3-21.5"
              />
            </g>
            <g id="_x32__1_">
              <defs>
                <path
                  id="SVGID_3_"
                  d="M31.6,90.9l0.8,2.3c0.5,1.2-1.9,2.6-2.7,3.1c-2.9,1.9-8.1,9.2-11.5,7.3c-0.2-3,1.6-3.8,1.7-6.2
					c0.6-0.5,1.4-0.9,1.4-1.8c0.1-2.5-4.1-4.7-5.5-3.3c-0.1,1-1.9,4.1-2.6,4.7c0,1,0.8,0.3,1.4,0.5c0.7-0.1,0.7-0.2,0.8-0.8
					c0.9,0.8,1.2,2.9,0,3.3c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.3-0.3-0.5-0.3-1c-0.5,0.1-0.7,0.2-0.9,0.4
					c-0.5-0.4-0.8-0.8-0.6-1.5c-4,7.2-8.1,9.8-11.9,15.1c0.1,1.1-0.7,1.8-1.1,2.9c-0.3,1.5,0.3,3,0.3,4.8c-0.7,2.1-0.1,4.4,0.3,6.2
					c0.6,0.3,1,0.4,1.4-0.1c0.2,0.2,0.3,0.9,0.3,1.2c0.2,0.1,0.1,0,0.3,0.1c1.4-0.1,3.4-1.4,3.7-2.6c0.2,0.1,0.1,0,0.3,0.1
					c1.3-0.1,1.4-0.9,1.8-1.8c0.7-0.8,2.2-0.9,2.5-2.2c2.8-2.4,5.7-4.5,8.2-7.3c1-0.6,2.1-1,2.1-2.3c1.7,0,3.5-1.2,4.4-2.7
					c0.3,0.1,0.2,0.1,0.6,0.3c0.9-1,1.5-0.7,2.7-1.4c1.3-0.8,1.5-2.7,3.1-2.9c0.5-0.6,0.3-1.6,0.7-2.3c0.6-0.6,1.5-0.9,1.5-1.9
					c0.9-0.4,0.4-1.4,0.6-2.1c0.1,0,0.2,0,0.3,0c0.1,0.2,0.1,0.5,0.2,0.7c0.9-0.1,1-0.8,1.6-1.2c2.1-0.5,2.9-2,4-3.6
					c0,0,0-0.1,0-0.1c0.3-0.1,0.2,0.1,0.3,0.1c1.4-1.6,2.9-5.3,4.9-6.3c-0.3-1.3,0.1-1.3,1-2.2c0.2-2.9,3.2-4.7,3.7-7.8
					c-0.1-0.8-0.1-1,0.6-1.1c0.2-1-0.3-1.4-0.9-1.9c0-0.8,0.1-1,0.3-1.5c0.8,0.1,1.2,0.6,1.6,1.1c1-0.9,0.8-3.2,2.9-2.6
					c-0.5-5.3,2.4-10.5,2.3-13.7c-0.5-1.5,0.1-1.2,0.4-2.3c-0.2-1.2-0.1-2.4,0.5-3.6c0.2-0.9-0.4-1.4-0.3-2.1
					c0.5-0.8,1.3-2.9,0.1-3.8c-1.6,3.7-2.1,12.1-5.5,15.3C44.9,79.4,39.1,86.9,34.8,91c-0.6-0.1-0.7-0.3-1.1-0.5L31.6,90.9z"
                />
              </defs>
              <clipPath id="SVGID_4_">
                <use xlink:href="#SVGID_3_" style="overflow: visible" />
              </clipPath>
              <path
                id="_x32_"
                class="st1 l02"
                d="M17.3,89.9c-9.7,18.4-14.4,32.9-14.4,32.9c7.4-7.2,47.7-19,54.5-72.1"
              />
            </g>
            <g id="_x33_">
              <defs>
                <path
                  id="SVGID_5_"
                  d="M102.3,14.4c1.1,0,4.9-0.6,5.5-1.1c2,0.5,4.1,1.1,5.7,2.1c-0.5,7.3-12.2,15.1-17.1,20.5
					c-1.2,0.9-3.4,1.8-4.4,2.7c-1.7,0.8-2.2,2.3-2.2,2.3c-2,3.3-4.4,5.4-7.4,6c1,0.9,2.4,1.7,7.6,0c0-1.3,0.6-0.8,1.2-1.1
					c-0.2-1.5,2.8-3.6,4-3.8c0-0.3,0.1-1,0.2-1.2c0.2,0,0.2,0,0.4,0.3c0.9-0.1,1.2-0.8,1-1.5c0.4,0.2,0.4,0.2,0.6,0.5
					c0.7-0.3,0.8-0.7,1.6-0.7c0.7-0.7,0.3-2,1.7-1.4c0-0.2,0-0.4,0-0.5c0.8-0.3,1.4-0.9,1.7-1.8c0.2,0.4,0.4,0.6,0.7,0.8
					c1.2-0.5,1.3-1.8,2.2-2.6c0,0,0-0.1,0-0.1c0.8,0.2,1.3-0.3,1.4-1.1c0.2,0,0.5,0.1,0.7,0.1c0.4-0.6,1-0.7,1-1.6
					c0.4,0.2,0.5,0.3,0.7,0.7c0.9-0.1,3.2-1.9,3.3-2.7c0.3,0.1,0.2,0.1,0.4,0.3c1.3-0.3,0.9-1.1,1.6-1.6c0.7-0.1,1.2-0.4,1.6-1.1
					c0.6-0.4,1.6-0.4,1.7-1.2c1.2,0.3,1.9-0.6,2.1-1.8c0.4,0.2,0.2,0,0.3,0.4c2.9-0.3,3.9-4.2,5.7-5.6c0.4-4.8-4.1-5.1-7.1-7
					c-4.6-2.5-11.7-1.9-17-0.4l-15.5,9.3c-2.5,1.4-6,2.4-8.4,4.2c0.1,0.8,0.3,1,0.9,1.2c0.1,1.1-1.2,1.1-1.8,1.6
					c-0.6,0-0.8-0.1-1.1-0.3c0.1-0.8,0.7-1.1,1.3-1.4c0-0.1,0-0.2,0-0.3c-1,0.2-2.1,1.1-2.8,1.6c-3.7,2.7-6.1,5-9.4,6
					c-2,0-2.3,0.9-3.5,1.5c-4.6,4.8-7.1,3.2-11.5,5.1c-1,0.4-2.1,1.6-4,1.1c-1.5-0.3-2.6-1.3-4.1-1.5c-0.3,5.3-0.7,7.7,4.6,9.3
					c0.6-0.1,0.9-0.4,1.2-0.8c0.3,0.4,0.5,1,0.8,1.4c5.7-0.4,3.3-1.7,6.5-3.4c0.1-1.1,1-1.7,1.6-2.3c0,0,1.3,0.7,2.1,0.7
					c-0.3-2.6,0.8-2.4,1.8-3.8c0.4-1.3,2.4-0.9,3.1-2.5l10.1-5.7c0.4-1,1.2-1.8,2.5-2.3c0.2-0.7,0.3-1,0.9-0.3
					c1.1-0.1,1-1.1,1.7-1.6c1.2-1.1,3-1.7,3.6-3.3c0.7,0.1,0.9,0.4,1.4,0.7c5.3-3.4,10.4-7.1,16.5-8.5C101,16,102.2,15.6,102.3,14.4
					"
                />
              </defs>
              <clipPath id="SVGID_6_">
                <use xlink:href="#SVGID_5_" style="overflow: visible" />
              </clipPath>
              <path
                class="st2 l03"
                d="M43,42.6c13.1,15.6,57.1-47.9,76.8-26.4c-8.9,10.4-26.2,27.5-34.8,29"
              />
            </g>
            <g id="_x34_567">
              <defs>
                <path
                  id="SVGID_7_"
                  d="M76.8,70.4c-0.6,0.7-1.8,0.8-3.1,1.3c-2.3,2.4-5.8,3.8-8.3,5.9c-1.4,1.9-0.6,5.6-1,7.5
					c0.2,0.8,0.7,0.7,1.2,1.1c0.9-0.1,2.6,0.4,3-0.8c2.1-0.1,5.3-0.1,6.9-1.1c5.9-3.3,11.2-5.7,14.2-5.7c1.4-0.4,1.7-3.8,3.2-3.3
					c0.3-0.3,0.5-0.5,0.6-1c0.2,0.1,0.2,0.1,0.6,0.1c0-0.4,0-0.5,0.1-0.7c0.3,0.1,0.3,0,0.4,0.1c0.5-0.2,0-1.2,0.8-0.7
					c0.4,0.1,2.7-1.1,3.1-1.6c0.4-1.3,0.2-1.2,1.4-1.2c0.5-0.9,0.8-1.8,2.1-2.1c0.2-0.7,0.1-1.6-0.5-2.1c-0.7,0-1.4-0.3-1.8,0.1
					c-0.6-0.2-0.9-0.7-1.3-1.1c-1.5,0-2.1,0.9-3.2,1.4c-1.8,0.8-4.1,1.2-5,2.7c-0.5-0.1-0.4-0.2-0.8-0.3c-3.5,0.9-6,2.1-8.4,2.3
					c-0.6,0.1,0.5-1.1,2-1.9c2.9-1.4,5.6-3.4,8.1-6.1c0.3,0,0.4,0,0.6,0.1c1.2-0.3,1.5-3.4,1.9-1.9c1-0.2,1-1,2.3-1.1
					c-0.1-0.4-0.5-0.8-0.2-1.6c0.5,0.4,0.7-0.4,1.1,0c1,0,1-0.7,1.1-1.5c0.3,0,1-0.1,1.1,0.4c1.8,0.1,1.4-3.1,3.1-1.6
					c0.4-0.4,0.9-1.3,1.6-0.8c0.2-0.4,0.3-0.7,0.5-1.1c2.4-0.1,4.3-1,5.9-3c0.2,0.3,0.2,0.6,0.6,0.7c0.2-2.1,0.7-1,0.8-0.7
					c2.1-0.2,1.6-1.8,3.5-1c0.7-0.2,1.2-1.1,1.7-1.4c1-0.5,1.2,0.3,2.2-0.5c0.3,0.3,0.7,0.6,1,1c1.1-1,2.6-0.6,3.8-1.1
					c0.3-1.4,1.9-2,2.2-2.2c0-3.5-2.9-2.8-4.9-4.5c-0.6-0.2-2.7,0.3-3.7,0c-3.3-2.2-7.2-1.5-10,1.4c-2.1,0.1-3.6,1.7-5.8,1.4
					c-0.4,1.1-1.7,0.9-2.7,1c-1.1,2.2-4.6-0.5-5.6,1.8c-1.6,0.7-3.8-0.5-4.5-1.3c0-1.1,3.8-7.1,3.8-7.1c-0.7,0-0.9-0.2-1.4-0.5
					c0.3-1,0.6-2.6,1.2-3.4c0.8-0.5,1.8-1,2.2-2.1c1-1.6,0.5-3.5,2.4-4.4c1.7-2.1,1.1-5.8,3.4-7.7c-0.4-1.4,0.8-2.2,0.6-3.6
					c0.3-3.2,0.8-4.9,1.3-5.9c-0.7-2.8-3-8.5-5.8-8.5c-0.2-0.2-0.4-0.7-0.6-0.8c-2.3,0.8-4-2.3-5.5-0.7c1.3,4-2.2,15.1-3.6,19.3
					c-0.1,2.5-1.2,3.9-2.3,5.1c0.1,1.5-0.6,1.7-0.8,2.7c-0.6,3.1-1,5.8-3.5,7.7c-0.3,2-2.5,3.2-2.7,4.9c-5.9,5.2,9.9,12.1-4.3,13.5
					c0.4-4.4,0.7-8.1,0.4-12.9c-0.4-0.1-0.4-0.1-0.7-0.3c0.2-0.3,0.3-0.3,0.7-0.4c0.6-2.8-0.2-6.2,0.9-8.5c-0.4-2.4-0.3-4.2,0.5-5
					c-0.6,0-0.8-0.1-1.1-0.3c-1.5-4.7-1.5-21.2-6.9-21.1C66,7.3,67.3,8.7,66.8,10c-0.2,0.4-0.7,0.7-0.5,1.6c1.5,2.2-0.6,2.5,0.5,4.4
					c-0.8,1.8,0.4,3.6-0.1,5.7c-0.9,3.6,0.2,8.2-1.7,11.5c0.3,2.4-0.3,3.7-1.2,4.7c0.6,0.2,0.9,0.6,1.4,1c0.9,3.5-0.5,6.3,0,9.7
					c0,2.5-1.6,5.5-0.4,8.1c0.4,1.3-0.5,1.8-1.7,2.2c-1.1,0.6-3.2,2.8-5.3,1.4l-5.4,2.3c-0.9,0.9-2,1.4-3.4,1.3
					c-1.2,1.3-2.1,3.7-1.5,5.5c-2,1.7-1.4,3.7-1.6,6l9-3.7c1.5,0.5,3.9-1.3,4.6-1.7c1.2-0.6,3.4-0.7,3.6-2.1
					c0.1,0.1,0.3,0.3,0.4,0.4c0.9-0.1,2.9-0.4,3.1-1.5c1.3,0.3,1.5-0.5,2.7-1c0-0.2,0-0.4,0-0.5c0.6-0.1,1-0.1,1.6-0.1
					c0-0.2,0-0.4,0-0.5c0.3,0,0.6,0,1,0c0.6-0.6,1.2-0.8,1.3-1.9c0,0,0.1,0,0.1,0c0.1,0.2,0.2,0.3,0.3,0.4c0,0,0,0.1,0,0.1
					c0.4-0.1,0.5-0.2,0.7-0.5c0.3,0,0.3,0.1,0.6,0.1c-0.2,0.2-0.2,0.2-0.5,0.3c0,0.1,0,0.2,0,0.3c1.3,0.1,1.8-0.4,1.8-1.5
					c6-1.7,8.3-3.9,13-5.7c1.9,0.3,1.2-0.6,4.8-2.1c0.8,0.1,1,0.5,1.6,0.8c-0.5,2.8-3.5,5.1-5.1,7l-1.5,0
					C82,69.1,79.1,70.3,76.8,70.4z"
                />
              </defs>
              <clipPath id="SVGID_8_">
                <use xlink:href="#SVGID_7_" style="overflow: visible" />
              </clipPath>
              <path
                id="_x34_"
                class="st3 l04"
                d="M67.8,7.9c3.6,7.5,1.8,39,0.6,46.9"
              />
              <path
                id="_x35_"
                class="st4 l05"
                d="M94.4,4.4c3.9,5.3-4.5,26.3-11.9,40.7"
              />
              <path
                id="_x36_"
                class="st5 l06"
                d="M47.2,69.8c12.6-3,53.5-30.3,74.7-24.7c-25.6,2.7-39.3,24.6-59.6,28.5"
              />
              <path
                id="_x37_"
                class="st5 l07"
                d="M98.6,67.8c-7.3,5.1-43.7,23.5-36.3,5.9"
              />
            </g>
            <g id="_x38_">
              <defs>
                <path
                  id="SVGID_9_"
                  d="M75,100.8c-0.8,0.5-1.9,0.8-2.8,1.5c-0.9,1.5-2.2,2.4-3.6,3.3c-0.5,1.3-1.7,1.4-2.9,1.8
					c-2.1,2.6-5.2,3.7-6.9,6.7c0.1,2.4,0.6,2.5,1.7,4c3.1,0.7,7.5-2.1,9-3.8c0.1,0,0.1,0,0.3,0.3c1.7-0.1,0.3-1.2,2-1
					c0.2-0.3,0.4-0.6,0.6-1c4.6-1.9,8.4-4.5,11.2-7.9c0.2,0.6,0.3,1.3,1,1.4c0.4-0.6,0.9-1.4,1.4-1.9c0,0,0-0.1,0-0.1
					c5.8-2.1,4.8-3.6,8.7-4.8c2.7-1.2,5.5-3.5,7.7-4.7c1.1,0.3,1.8-1.1,2-2c0.6,0,0.7-0.1,1.1-0.3c0-0.2,0-0.4,0-0.5
					c1.2-0.1,0.6-1,1.3-1.5c0.1,0.2,0.2,0.2,0.3,0.5c0.3,0,0.3-0.1,0.5-0.1c-0.1-1.5-0.3-2.1,0.7-3c0.8-3-4.1-3.3-6.1-4.4
					c0-1.2,1-2.9,1.6-3.7c-2.2-5.7-9.7,2.6-12.9,1.4c-6.9,3-14.6,6.6-14,4.3c-1.6,4-9.8,5.2-6.7,10.8c-0.1,1.3-0.3,2.2,1,2.7
					c0.6-0.1,1.2-0.3,1.8-0.4c0.6-1.1,1.4,0.3,2-0.8c0.4,0,0.7,0,1,0.1c0,0,8.8-5.9,11.7-6.2C88,95.8,75.8,102.3,75,100.8z
					 M92.1,94.4c2.2-1.5,3.3-3.3,4.8-5.1c0.3-0.7,0-1.7,1-1c0.1-0.2,0.2-0.4,0.2-0.5c1.1,0.9,3.4,2.3,2.7,4
					C100,93.4,88.5,100.6,92.1,94.4z"
                />
              </defs>
              <clipPath id="SVGID_10_">
                <use xlink:href="#SVGID_9_" style="overflow: visible" />
              </clipPath>
              <path
                class="st6 l08"
                d="M69.9,94.7c10.3-7.9,28.7-14,30.3-18.7c-2.1,6.9-29.7,34.8-41.7,38.7c6.7-1.8,45.4-13.5,47.3-21.9
				s-8.4-11.4-8.4-11.4"
              />
            </g>
            <g id="_x39_">
              <defs>
                <path
                  id="SVGID_11_"
                  d="M90.8,80.7c-0.7-0.3-1.2-1.1-1.4-2.7c0,0,0.1,0,0.1,0c-1-4.7-0.9-7.3-0.2-8.9c-1.4-2.7,1.9-2.5-1.4-10.5
					c0-1.1,0.8-1.7,1.2-2.5c0.5-3.3-14.6,4.5-13,5.7c0.5,0.4,0.9,0.9,1.4,1.2c0.4,1.6,0.8,4-0.5,5.5c0.1,0.9,0,1.5-0.3,1.9
					c0.7,6.1,0.3,10.7-1.3,13.9c0.7,0.1,0.8,0.4,1.3,0.7c0,3.7-1,12.5-1,12.5c0.1,1.3-0.2,3-0.8,3.4l-2.7,11.8
					c1.5,0.8-4.5,9.5-5.4,10.7c0,0.2,0,0.4,0,0.5c0.4-0.1,0.8-0.4,1.1-0.7c0.3,0.4,0.4,0.8,0.3,1.2c-0.2-0.1-0.3-0.1-0.4-0.3
					c-0.3,0.2-0.3,0.1-0.4,0.5c-0.3-0.2-0.2-0.3-0.3-0.7c-2.8,2.2-3.8,6.9-6.3,9.7c-0.1,0-0.2,0-0.3,0c-0.3-0.5-0.7-1-0.8-1.6
					c-0.1,0-0.2,0-0.3,0c0.3,0.7,0.2,1.2,0.2,1.9c-0.2-0.2-0.3-0.3-0.4-0.5c-0.3,0.2-0.2,0-0.3,0.4c0,0-0.1,0-0.1,0
					c-0.3-0.8-0.6-0.5-1-1.2c-0.1,0-0.2,0-0.3,0c0.8,2.4-1.9,5.6,0.6,6c0.1-0.6,0.2-0.6,0.3-1.1c0.6,0.5,0.5,0.6,0.8,0.1
					c0,0.1,0,0.2,0,0.3c1-0.1,0.6-0.7,1-1.4c0.2,0.1,0.6,1.4,0.7,2.1c0.5-0.1,0.4-0.2,0.8-0.4c0.2,0.4,0.3,0.5,0.5,1
					c0.4,0,0.6-0.1,0.8-0.1c0.1-0.2,0-0.1,0.1-0.3c0,0,0-0.1,0-0.1c-0.5-0.1-0.2,0-0.7,0c0.4-0.6,1.1-0.5,1.6-1c0,0,0.1,0,0.1,0
					c-0.1,0.4-0.3,0.7-0.6,1c0.9,1.2,2.3-0.5,2.2-1.5c0.2-0.1,0.4,0,0.7-0.3c0,0,0.1,0,0.1,0c0,0.7-0.1,0.7-0.2,1.2
					c0.1,0,0.2,0,0.3,0c2.7-3.3,4.2-7.3,6.9-10.5c-0.1-0.3,0-0.3-0.2-0.4c0.1-0.9,2-3.9,2.9-4.1c0-0.7,0.1-1.3,0.9-1.5
					c0.8-1.4,1.9-2.3,3.1-3.1c0.8-1.2,1-3,2.1-3.8c-1.3-3.8,0.4-5.9,0.8-9.9C86.9,95.2,90.3,85.8,90.8,80.7z M62.1,135.2
					c-0.2,0.4-0.6,0.2-0.9,0.7c-0.1,0-0.2,0-0.3,0c0.9-1.5,2.1-2.9,2.8-4.5C63.9,131.7,62.5,134.8,62.1,135.2z M63.8,131.2
					c0-0.1,0.1-0.2,0.1-0.3C63.9,130.9,64,131,63.8,131.2z M77.9,124.5c0.1,0.1,0.1,0.1,0.2,0.4c0,0-0.1,0-0.1,0
					C78,124.8,77.9,124.6,77.9,124.5z"
                />
              </defs>
              <clipPath id="SVGID_12_">
                <use xlink:href="#SVGID_11_" style="overflow: visible" />
              </clipPath>
              <path
                class="st7 l09"
                d="M80.8,55.7c9.2,27.5-2.7,62.5-19.1,81.8"
              />
            </g>
            <g id="_x31_0">
              <defs>
                <path
                  id="SVGID_13_"
                  d="M86.3,107.1c0.3,0,0.6,0.1,0.8,0.1c0,0.3,0,0.6,0.1,1c-0.4,0-0.5,0.2-1,0
					C86.2,107.8,86.2,107.4,86.3,107.1z M96.4,116.7c-0.1-0.2-0.4-0.6-0.6-0.8c-1.4-0.3-2.4,0.2-3.5,0.4c-0.9,0-1.8-0.4-2.4,0.4
					c-0.9,0.3-2.5-0.3-2.7,0.8c0.3,0.3,0.7,0.5,1,0.8c0.3-0.2,0.3-0.3,0.4-0.7c0,0,0.1,0,0.1,0c0.4,0.6,0.8,1.3,1.2,1.9
					c0.2,0,0.5-0.1,0.7-0.1c0.7,0.7,1.4,2,2.3,2.5c1,0.5,1.8,0,2.1,1.2c0,0,0.1,0,0.1,0c0.5-2.5,0.7,1.4,6.1,4.1
					c0.6,0.3,0.5,1,0.9,1.5c0.5,0.6,1.5,0.2,2.1,1.1c0.3-0.1,0.2-0.1,0.4-0.3c0.4,0.1,0.4,0.1,0.7,0.3c-0.1,0.3-0.1,0.2-0.4,0.4
					c0.9,0.9,2.5,1.6,3.7,2.1c-0.2-0.4-0.2-0.7-0.2-1.1c0.1,0,0.2,0,0.3,0c0.2,0.7,0.6,1.2,0.9,1.8c1.7,0.6,5.1,0.3,6.5,1.2
					c0.3-0.5,0.7-1.1,1.3-0.7c0.3-0.3,0.5-0.5,0.8-0.8c0.2,0,0.4,0,0.5,0c2.5-3.5,0.2-9.4-1.9-12.6c-0.7-0.8-2.1-0.9-2-2.2
					c-0.5-0.8-1.3-0.9-1.5-2.2c-1.2-1-3-1.6-4.3-0.8c-0.5,0.1-0.6-0.3-0.8-0.4c-0.7-0.2-1.7,0.1-2.2,0.1c-1.1,0-1.7-0.8-2.8-0.8
					c-1,0.9-1.4,0.1-2.4,0.3c-1,0.2-2.3,0.9-2.9,1.5c-0.5,1.5,0.9,3.5-0.2,4.5C95.9,120,96.9,118.1,96.4,116.7z M88.7,115.6
					c-0.1-0.7-0.3-0.7-0.6-1.1c-0.3,0-0.6,0-1,0c-0.1,0.1,0,0.1-0.3,0.1c-0.1,0.2,0,0.1-0.1,0.3c0,1.1,0.2,1.1,0.8,1.6
					c0.9,0,1.1,0,1.2-0.7c0,0,0.1,0,0.1,0C88.9,115.6,88.7,115.7,88.7,115.6z"
                />
              </defs>
              <clipPath id="SVGID_14_">
                <use xlink:href="#SVGID_13_" style="overflow: visible" />
              </clipPath>
              <path
                class="st8 l10"
                d="M87.7,109.6c10.9,11,28.2,16.4,28.2,16.4"
              />
            </g>
          </g>
          <g id="B">
            <g>
              <defs>
                <path
                  id="SVGID_15_"
                  d="M196.7,37.7c0.3-1.8,2.7-2.2,4-3c0.7-0.9,1.8-1,2.7-1.3c0.3,0.2,0.1,0,0.1,0.4c2.8-0.1,4.6-2.6,7-3
					c2.5-1.8,6.4-1.9,7.2-5.1c-0.2-1.1-0.4-1.9,0.5-2.7c0.5-1.5-0.3-3.3-0.6-4.1c-1.3-4.9-4-5.7-7.1-4.9
					c-2.4,0.6-24.9,11.7-26.6,10.4c-0.3,0.5-0.7,0.9-0.8,1.4c-0.2,0-0.3,0-0.5,0c0,1.3-3.6,2-4.8,2.2c-0.1,0.8,0.2,1-2.3,0.1
					c0.1,0,0,0.2,0,0.4c0.3,0.1,0.5,0.1,1,0.1c-1.2,0.8-3.3-1-5-0.6c-2.6,0.4-5.2,0.5-6.8,2.8c0.4,6,10.3,3.7,14,7.2
					C179.9,36.5,181.7,39.6,196.7,37.7z M203.8,35c0-0.1,0-0.2,0-0.2c0.3,0,0.2,0,0.4,0.1c0,0-0.1,0-0.1,0
					C203.9,34.9,204,34.9,203.8,35z"
                />
              </defs>
              <clipPath id="SVGID_16_">
                <use xlink:href="#SVGID_15_" style="overflow: visible" />
              </clipPath>
              <path
                class="st9 l11"
                d="M166.9,30.8c27.4,5.5,31.1-3.7,44.7-9.3"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_17_"
                  d="M186.1,52.5c0,0,0.1,0,0.1,0c0.1,0.2-0.1,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C186,52.7,186,52.7,186.1,52.5z M185.6,52.2c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C185.6,52.4,185.6,52.3,185.6,52.2z M185.5,52.6c0,0,0.1,0,0.1,0c0.1,0.2-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
					C185.4,52.8,185.4,52.7,185.5,52.6z M175.3,62.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0-0.2,0.1-0.4,0.1-0.6
					C175.4,61.9,175.4,61.9,175.3,62.1z M174.9,80.5c0,0.1,0,0.2,0,0.4c-0.1-0.1-0.1-0.1-0.1-0.4C174.9,80.5,174.9,80.5,174.9,80.5z
					 M174.9,84c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0C174.9,84.2,174.9,84.1,174.9,84z M175.3,90.9c0,0-0.1,0-0.1,0
					c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.1,0C175.3,90.7,175.3,90.8,175.3,90.9z M175.2,89.3c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
					c0-0.4,0.1-0.7,0.1-1.1C175.4,88.6,175.4,89.1,175.2,89.3z M177,66.4c0,0.1,0,0.2,0,0.4c-0.1-0.1-0.1-0.1-0.1-0.4
					C176.9,66.4,177,66.4,177,66.4z M175.9,74.5c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C175.9,74.6,175.9,74.5,175.9,74.5z M176,75.7c-0.1-0.2-0.1-0.3-0.1-0.6C176,75.2,176,75.4,176,75.7z M175.4,75.2
					c0.1,0.1,0.2,0.2,0.2,0.2c0,0-0.1,0-0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0C175.4,75.5,175.4,75.3,175.4,75.2z M175.9,80.4
					c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.1,0C175.9,80.2,175.9,80.3,175.9,80.4z M175.8,80c0,0-0.1,0-0.1,0
					c-0.3-1-0.1-2.9-0.1-4.1l0.7,1.6c0,0.2-0.3,0.5-0.2,0.8C176.7,78.7,175.8,79.2,175.8,80z M176.2,87.3c-0.1-0.1-0.1-0.2-0.1-0.5
					C176.1,86.9,176.1,87,176.2,87.3z M176.3,87.5c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2
					C176.2,87.5,176.2,87.5,176.3,87.5z M176.4,89.1c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0-0.2,0c0-0.6,0.1-0.8,0.2-1.1
					C176.6,88.5,176.6,88.9,176.4,89.1z M176.9,74.5c-0.1-0.1-0.1-0.1-0.1-0.4c0,0,0.1,0,0.1,0C176.9,74.2,176.9,74.3,176.9,74.5z
					 M176.9,69.1c-0.1,0.2-0.4-0.3-0.5-0.5c0.2-0.2,0.3-0.4,0.5-0.7C177.1,68.2,177.2,68.8,176.9,69.1z M177.6,68.8
					c0.1,0.2,0.1,0.7,0.1,1.1C177.5,69.5,177.6,69.2,177.6,68.8z M185.9,71c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C185.9,71.1,185.9,71,185.9,71z M185.8,71.3c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C185.8,71.5,185.8,71.4,185.8,71.3z M185.5,72.9c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2
					C185.4,72.9,185.4,72.9,185.5,72.9z M185.1,55.7c0.1,0,0.2,0.1,0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0
					C185,56,185.1,55.9,185.1,55.7z M185.3,73.3c0.2,0.5-0.4,1.6-0.5,2.3c0,0-0.1,0-0.1,0C184.8,74.7,185.1,73.9,185.3,73.3z
					 M184.5,77c0,0-0.1,0-0.1,0c0.1-0.4,0.2-0.9,0.2-1.3C184.9,76.1,184.6,76.5,184.5,77z M184.1,69.3c0.1,0,0.2,0.1,0.2,0.1
					c0,0.1,0,0.2,0,0.2C184.1,69.5,184.2,69.7,184.1,69.3z M184.2,78.2c0,0-0.1,0-0.1,0c0-0.3,0.1-0.6,0.1-1
					C184.4,77.5,184.3,77.8,184.2,78.2z M183.8,56.2c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0.4-0.1,0.8-0.1,1.2c0.1,0,0.2,0,0.4,0
					c0-0.2,0.1-0.4,0.1-0.6c0.1,0,0.2,0,0.2,0c-0.1,0.4-0.2,0.7-0.2,1.1c-0.4-0.2-0.4-0.3-0.8-0.4C183.5,57.1,183.6,56.5,183.8,56.2
					z M183.4,58.2c0.1,0,0.2,0.1,0.2,0.1c0,0.1,0,0.2,0,0.2c-0.1,0.1,0,0-0.1,0.2c-0.1,0-0.2,0-0.2,0
					C183.3,58.6,183.4,58.4,183.4,58.2z M183.8,73.4c0,0-0.1,0-0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0c0-0.1,0.1-0.2,0.1-0.4
					C183.7,73.3,183.7,73.3,183.8,73.4z M183.2,60.2c0.4,0.5,0,1.7-0.2,2.1c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					C182.8,61.5,182.9,60.8,183.2,60.2z M182.7,63.5c-0.1-0.1-0.1-0.1-0.1-0.4c0,0,0.1,0,0.1,0C182.7,63.2,182.7,63.3,182.7,63.5z
					 M182.7,62.6c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0C182.7,62.8,182.7,62.7,182.7,62.6z M204.7,79.5
					c0,0.1,0,0.2,0,0.4c-0.1-0.1-0.1-0.1-0.1-0.4C204.6,79.5,204.6,79.5,204.7,79.5z M204.8,80.3c0,0.1,0,0.2,0,0.2c0,0-0.1,0-0.1,0
					c0-0.1,0-0.2,0-0.2C204.7,80.3,204.7,80.3,204.8,80.3z M195,50.7c0,0,0.1,0.1-0.1,0.2C194.9,50.8,195,50.7,195,50.7z
					 M194.3,51.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1,0.3-0.4,0.3-0.5,0.6C194.2,51.4,194.2,51.4,194.3,51.3z M193.8,51.8c0,0,0.1,0,0.1,0
					c-0.1,0.1-0.5,0.8-0.7,0.7C193.4,52.2,193.6,52,193.8,51.8z M192.7,52.8c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0,0,0.1,0.2
					C192.9,53,192.5,52.9,192.7,52.8z M192.1,53.8c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0.2-0.3,0.4-0.6,0.6-0.8c0,0,0.1,0,0.1,0
					C192.5,53.3,192.3,53.6,192.1,53.8z M203.3,81.7 M203.7,97.1c-1.5-0.8-3.8-3-3.5-4.7c-1.1,0.2-1.5-2-1.7-4.6
					c-2.4-0.3-2.5-2.3-2.2-6.1c-1.5,2.6-2.3,9.5-1.8,12.3c0.2,1-0.2-1.2-0.7-1.2c0.5,4.1,0.7,5.6,1.5,6.5c0,0.2-0.1,0.3-0.1,0.5
					c0.5,0.8,1.7,1.4,1,2.4c1.6,1.6,2.4,3.6,2.8,5c0.7,2.6,0.9,1.3,1.7,2.5c1.2,1.9,5.5,6.2,13.3,1.4c0.3-0.2,0-0.7,0.2-1
					c0.2-0.2,0.6-0.2,0.7-0.5c0.5-1-0.2-1.8,0-2.4c0.5-1.4-0.8-1.8-1.4-2.1c-0.8-1.7-1.2-4.3-1.2-7.6c0-1.1,0.4-3.1-0.2-3.7
					c0,0.2-0.2,0.1-0.2,0.1c0.1-0.5-0.1-0.3,0.4-0.4c0.2-3.5-0.1-6.9-0.5-10.1c-0.1,0-0.2,0-0.2,0c-0.3-0.4-0.3-0.9,0-1.2
					c-0.1-0.5,0-0.2-0.5-0.2c0.3-0.8,0.3-2.1,0.6-2.8c-1,0.1,0.5-5.1,0.1-6.3c-0.2-0.5-0.6-1.1-0.1-1.4c-1.6-9.3,0.2-19.1-1.4-28
					c-8.8-4.8-18.3,7.1-24.3,12.1c-0.4,0-0.5,0-0.7,0c0.2-1.1,0.6-2.4,1.3-3c-0.2-0.1-0.3-0.1-0.7-0.1c0.1-0.4,0.2-0.5-0.1-0.7
					c0.3-0.4,1.2-1.1,0.8-2.2c0.3-0.3,0.6-0.6,1-1c-0.4-0.6,0.4-1.2,0.6-1.6c-0.2-1.3,1.1-2.2,1.7-3.3c0.1,0,0.2,0,0.4,0
					c0.2-1,1.3-1.6,1.4-2.5c0.3,0,0.6-0.1,1-0.1c0.1-0.8,1.6-2,2.5-2.1c0.1-0.6,0.6-0.7,1-1.2c0.6-7.6,5.8-19.3,6.8-20.5
					c0-1-0.5-1.3-0.7-1.9c-0.2-0.6,0-1.5-0.6-1.8c-0.2-0.8-0.1-1.6-0.8-2.1c-1.7-3.7-1.8-9-6.8-9.1c-4.5-1-6.3,4.7-5.2,8
					c-0.3,2.6,1.3,5.1-0.1,7.6c-0.3,0-0.4,0.1-0.6,0.1c0.1,0.6-0.1,0.8-0.1,1.4c-0.8,0-0.7,0.9-1.1,1.3c-0.9-0.1-0.7,0.8-1,1.4
					c-0.4,0.2-0.5,0.3-0.7,0.8c-2.7,5.2-2.9,11.3-6.5,14.9c0.2,1.3,0.1,1.5-0.2,2.9c0,1.7,0.2,3.4-0.7,4.8c0.1,2.6-0.8,5.4-1,8.1
					c-0.1,0-0.2,0.1-0.4,0.1c0.2,0.5,0.1,1.5-0.2,2.1c0,0.7,0.3,1.1-0.2,1.4c0.2,0.7-0.5,4-0.5,3.1c-0.4,0.2-0.4,0.7-0.7,1
					c0,0.5,0,0.7,0.4,0.8c-0.2,0.3,0,0.1-0.4,0.2c0,0.2,0,0.3,0,0.5c0.3-0.2,0.1,0.1,0.2-0.2c0,0,0.1,0,0.1,0
					c-0.2,0.6-0.3,0.7,0.1,1.1c0,0.7,0.1,0.5-0.5,0.5c0.7,1-0.1,2.7,0.4,3.7c0.7-0.1,0.6-0.4,0.6-1.2c0.4,0.5,0.2,3.8,0,4.2
					c0.1,0.5,0,0.4,0.4,0.4c0,0.4-0.1,0.3,0,0.5c-0.1,0.8-0.3,0.2-0.5,0.6c0,0.5,0.1,1,0.1,1.4c-0.7-1.5,0.1-3-0.1-5.1
					c-0.2,0-0.4,0-0.6,0c-0.6,3.1-0.1,6.7-0.1,9.9c-0.3-1.4-0.1-3.6-0.5-5c-0.1,0-0.2,0-0.2,0c0,3.6,0.1,6,0.1,8.8
					c-0.1,0-0.2,0-0.4,0c-0.2,1.2,0.5,2.2,0,3.4c0.2,0.1,0.3,0.2,0.5,0.4c-0.2,0.2-0.1,0.1-0.4,0.2c0.6,3.1,1.3,9-0.6,11.1
					c3.5,1.6,7.1-2.1,10-8.6c0.3-0.5,0.7-0.9,1.2-1.2c0-0.8-0.5-0.9-0.6-1.6c-0.7,0.1-0.7,0.1-1.2,0.1c0.3-1.4,0.3-2.8,0.8-4.1
					c0.1,0,0.2-0.1,0.4-0.1c0.1-2.3,0.5-4.5,1.3-6.6c2.3-10.3,3.7-15.6,7.7-20.3c0-0.4,0-0.4,0.2-0.6c-0.2-0.1-0.2-0.1-0.5,0
					c0.4-0.6,0.9-0.9,1.2-1.6c0.2,0,0.3,0,0.5,0c0-0.2,0-0.3,0-0.5c2.6-1.5,4.3-4.7,7.5-5.7C202,58.4,204.4,89.2,203.7,97.1z
					 M204.7,89.7c0,0.1,0,0.2,0,0.2c-0.2-0.2-0.2-0.5-0.2-1C204.6,89.2,204.7,89.5,204.7,89.7z M204.4,88.7c0-0.4,0-0.8,0-1.2
					c0.1,0.1,0.2,0.2,0.2,0.2c0,0.3,0,0.6,0,1C204.6,88.7,204.5,88.7,204.4,88.7z M204.8,94.9c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.2
					c0,0,0.1,0,0.1,0C204.8,94.7,204.8,94.8,204.8,94.9z M204.8,91.1c-0.1,0-0.2,0-0.2,0c0-0.3,0-0.6,0-0.8c0.1,0,0.2,0.1,0.2,0.1
					C204.8,90.6,204.8,90.9,204.8,91.1z M204.5,83.8c-0.2,0.8,0,1.9,0,2.8c-0.9-1.2-0.3-5.5-0.4-7c0.4,0.6,0,1.4,0.2,2.1
					c0.4-0.2,0.1-0.2,0.2-0.4c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.1,0c0.1,1.1,0.2,1.9,0.1,3c-0.1-0.1,0,0-0.1-0.2
					C204.7,83.8,204.6,83.8,204.5,83.8z M205,86.2c-0.2-0.3-0.1-1-0.1-1.4C205.1,85,205,85.7,205,86.2z M188.6,51
					c0-0.3,0.1-0.4,0.1-0.6c0.1,0,0.2,0,0.2,0c0,0.2,0,0.3,0,0.5c0,0-0.1,0.1-0.1,0.1C188.8,51,188.7,51,188.6,51z M195.7,54.8
					c-0.2,0-0.3,0.1-0.5,0.1c0.1-0.3,0.1-0.3,0.2-0.5C195.7,54.6,195.6,54.5,195.7,54.8z M194.9,55.1c0.1,0,0.2,0,0.2,0
					c0,0-0.1,0.1-0.1,0.1C194.9,55.3,194.8,55.3,194.9,55.1z M174.2,73.7c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.3-0.2-0.5
					c0.2-0.1,0.1,0,0.2-0.1c0.1,0,0.2,0,0.2,0c0,0.2,0,0.5,0,0.7C174.4,73.7,174.3,73.7,174.2,73.7z"
                />
              </defs>
              <clipPath id="SVGID_18_">
                <use xlink:href="#SVGID_17_" style="overflow: visible" />
              </clipPath>
              <path
                class="st10 l12"
                d="M193.5,6.9C205,37.2,162,37.5,176,89.8c-1-24.4,8-44.8,27.8-53.9c7.1,1.1,8,70.7,8,70.7
				c-6.4,0.6-15.5-5.8-15.9-23.9"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_19_"
                  d="M183.4,89.3l-9.8,8.7c-1.5,2.2-5.4,2.1-7.4,3.9c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1
					c1.2-0.1,2.4-0.6,3.1-1.2c0.3,0,0.6,0,0.8,0c-0.2,0.3,0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.2c0.1,0,0.2,0,0.4,0c0,0.1,0,0.2,0,0.4
					c-0.4,0-0.6,0-0.7-0.4c-0.6,0.2-0.6,0.7-1.1,1.1c0,0.1,0,0.2,0,0.2c0.6-0.1,0.8-0.3,1.2-0.5c0,0,0.1,0.1,0.1,0.1
					c-0.9,0.8-1.3,0.4-2.3,1c-1,1.1-2.6,1.3-4,1.7c0,0.1-0.1,0.2-0.1,0.4c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0.8-1.1,0.1-1.7,0.2
					c-0.2,0.1-0.4,0.4-0.6,0.5c-0.4,0.1-0.7-0.3-1.2,0c-0.9,0.6-2.5,0.1-3.4,0.7c-0.1,0-0.4-0.2-0.6-0.1c0,0.1,0,0.2,0,0.2
					c-0.9,0.3-1.8,0.2-2.5,0.5c4.9,0.5,10.3-2,14.9-3c0.1,0.1,0.1,0.1,0.1,0.4c4.8-1.8,9.1-3.4,14-4.7c0-0.1,0-0.2,0-0.4
					c0.4-0.2,2.4-1.3,3-1c-0.1,0.4,0.1,0.4,0.6,0.2c0.1-2.2,2.5-1.7,4.2-2.3c1.9-1.3,2.9-0.6,4.7-0.2c2-0.2,3.7-1.6,6-3.9
					c0.1-0.4,0.3-0.8,0.8-1.2c0.1-0.2,0.2-1.2,0.2-1.2s-0.5-0.1,0.4-1.9c0.4-0.8-1.1-0.1,0.6-3.1c2.9-5.1,1.9-14.4-1-16
					c-0.4-0.2,0.1-0.2-0.3-0.8c-1.1-1-0.4-1.8-1.6-1.8c-0.8-2.6-1.2-5.9-2.9-8.1c-0.1,0-0.2,0-0.4,0c-0.1-1-0.7-1.3-1.1-2.1
					c-0.4-0.1-1.4-0.4-1.9-0.7c-4.3-1.3-7.4,9.2-6.8,12.7c0.6,3.5,1.5,2.8,1.5,2.8c0.2,1.2,1.2,2.2,1.7,3c-0.4,1.1,1.1,2.4,0.6,3.6
					c0.2,0,0.3,0.1,0.5,0.1c0.6,1.3-0.6,2.6-0.1,4c-0.4,1.1-1.2,2.8-2.3,3.4c-0.1,0.4-0.2,0.7-0.2,1.1c-0.8-0.3-1.9,2.5-1.8,0.6
					c-0.5,0.3-0.8,1.2-0.7,1.7c-0.5,0.1-1,0.4-1.3,0.7C183.8,89.3,183.6,89.3,183.4,89.3 M170.4,99.2c-0.5,0.1-0.4,0.3-0.8,0.5
					c0-0.1,0-0.2,0-0.4c0.3-0.1,0.4-0.2,0.6-0.4C170.4,99.1,170.2,98.9,170.4,99.2z M167.7,100.8c-0.2-0.2-0.2-0.2-0.2-0.5
					c0.3-0.1,0.3-0.1,0.5-0.2c0.3,0.2,0.1-0.1,0.2,0.2C167.8,100.4,167.9,100.5,167.7,100.8z M168.7,106c-0.2,0-0.3,0.1-0.6,0.1
					c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.2-0.2C168.7,105.9,168.5,105.7,168.7,106z M166.1,107
					c0-0.1,0.1-0.2,0.1-0.4C166.5,106.8,166.3,106.8,166.1,107z M168.1,108.2c0.2,0,0.4,0,0.6,0c0,0,0,0.1,0,0.1c-0.1,0-0.2,0-0.2,0
					c-0.4,0.3-1.1,0.4-1.6,0.6c0-0.1,0-0.2,0-0.2C167.2,108.6,167.8,108.4,168.1,108.2z"
                />
              </defs>
              <clipPath id="SVGID_20_">
                <use xlink:href="#SVGID_19_" style="overflow: visible" />
              </clipPath>
              <path
                class="st11 l13"
                d="M191.8,63.5c5.3,8.3,18,27.2-34.1,41.7"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_21_"
                  d="M214.9,105.9c-0.8-2.3,16.2-5.3,19.1-4.7c-0.2,0.1-0.3,0.1-0.6,0.1c0,0.1,0,0.2,0,0.4
					c2.4,0.2,4.5,0.7,6.6,1c0.8,0.3,1.6,1.6,3,1.1c1.3-0.6,1.3-2.2,2.8-2.7c0.5-2.6-0.8-4.5-2.8-5.3c-0.7-0.2-1.3-0.2-1.8,0
					c0.2,0.4,0,0.3,0.5,0.4c-0.6,0.1-1.3,0.1-1.9,0.1c-0.2,0,0.3,0.9-0.7,0c-3.5,0-7.4-0.5-10.7,0.1c0,0,0-0.1,0-0.1
					c0.2-0.1,0.3-0.2,0.5-0.4c0,0-0.1-0.1-0.1-0.1c-1.1,0.2-2.6,0-2.8,1c-0.4,0-0.9,0-1.3,0c0.1-0.2,0.1-0.2,0.1-0.5
					c-0.5,0-1.1,0.2-1,0.6c-0.5-0.2-0.3,0-0.2-0.5c-3.5,0.8-7.4,2.1-11.1,1.2c0.3,1.8-3.6,1-16.2,4.7c-0.5-0.3-0.2-0.6-0.6-0.5
					c-0.1,0.2-0.2,0.4-0.2,0.6c0.2,0.2,0.1,0.2,0.5,0.2c0.2,0.6-1.2,0.4-1.6,0.7c-0.3-0.7-0.6-0.4-0.7-0.4c0,0.2,0,0.4,0,0.6
					c-4.7,1.1-8.3,2-12.9,2.4c-0.1,0.2-0.1,0.2-0.1,0.5c-0.8-0.2-3.3,0.5-3.9,0.6c0.6,0.3-0.9,0.7-1.1,0.8c-0.1-0.1-0.1-0.1-0.1-0.4
					c-0.7,0.2-1.8,0.2-1.9,1c-0.4-0.1-0.3,0.1-0.4-0.2c-6.3,1.5-10.6,3.4-17.5,2.8c0,0.1,0,0.2,0,0.4c-0.1,0-0.2,0-0.4,0
					c0,0,0.1-0.1,0.1-0.1c-2.1-0.4-5.6-1-7.5-2.2c0.1-0.3,0.1-0.4,0.4-0.6c-2.6-2.4-3.4-0.3-5.9-0.1c-0.6,0.2-0.9,1.7-1.6,1.2
					c0-0.4,0.1-0.5,0.1-0.8c-0.3,0.3-0.6,0.6-0.8,0.8c0,0.5-0.1-0.1-0.1-0.1c-0.1,0.2-0.2,0.3-0.4,0.5c0,2.5,1.3,3,2.4,4.3
					c-1-0.5-2.1-2.4-2.8-2.5c0.2-0.2,0.2-0.1,0.2-0.5c-0.1,0-0.2,0-0.4,0c0-0.3-0.2-0.7-0.2-1c-0.2,0.4,0,1.6-0.7,0.8
					c0,0-0.1,0-0.1,0c0.9,6.5,13.9,7.3,19.2,8.2c7.7-0.4,14-4.1,21.3-5.9c5-0.9,9.6-2.7,14.5-4c2-0.5,3.9-2.7,6-2.3
					C212.2,105.3,213.4,107.8,214.9,105.9z"
                />
              </defs>
              <clipPath id="SVGID_22_">
                <use xlink:href="#SVGID_21_" style="overflow: visible" />
              </clipPath>
              <path
                class="st12 l14"
                d="M139.9,105.7c6.8,23.3,77.2-9.1,102.8-6.4"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_23_"
                  d="M165,137.3c-4.8,0.4-9.7,0.5-13.3-3.1c-1.4-1.7-3.7-2.7-3.1-5.4c0.3-1.1,1.8-3.7,3.6-2.9
					c1.2-0.4,5.2-1.4,5.1,1c-0.6,0-0.8,0-1.4,0c-0.2,0.5-0.6,0.7-1.1,1c2.1,2.7,6.3,0.4,8.8,0.8c-0.1,0.1-0.2,0.2-0.2,0.2
					c2.6,0.5,4.4-1.4,6.4-2.5c0.1,0.2,0.1,0.6,0.1,1C168.6,132.5,175.2,133.6,165,137.3z"
                />
              </defs>
              <clipPath id="SVGID_24_">
                <use xlink:href="#SVGID_23_" style="overflow: visible" />
              </clipPath>
              <path
                class="st13 l15"
                d="M153.8,125.5c-3.6,3.1-3.5,13.8,15.7,5.9"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_25_"
                  d="M243.4,129.9c-0.2,0.4-1.1,1-0.7,1.6c-0.7,0.4-0.6,1.3-1,1.9c-0.7,0.9-1.9,1.3-2.7,2.2
					c-0.8,0-1.7-0.3-2.3,0.2c-1.1,0-2.6-0.7-2.9-1.7c-0.3,0.3-0.5,0-1.2-0.1c-0.4-1.1-3.6-2.2-3.9-3.4c-2.7-0.3-3-2.2-3.4-4.3
					c-0.1,0-0.2-0.1-0.4-0.1c-0.5-1.3-1.6-2.6-2.9-3.1c-0.2-0.8-0.7-1-1.4-1.2c-0.1-0.2-0.2-0.4-0.4-0.6c-1.9,0-2.2-2.3-3-3.6
					c-2.5-3.4,0-6.8,4.1-6c0.2-0.5,0.6-0.6,1-0.4c1.2-1,3.3-0.3,5-0.2c0.4,0.8,1.5,0.5,2.3,0.8c1,0.9,2.4,0.7,3.1,1.9
					c0.5,0.2,1,0.1,1,0.7c0.6,0.3,1.3,0.6,1.4,1.3c0.5-0.2,1.4,0.6,1.8,0.8c0,0.2,0.1,0.5,0.1,0.7c1.9,1.7,4.5,4.3,5.1,6.8
					c0.2,0.2,0.4,0.4,0.6,0.6c0,0.4,0.1,0.8,0.1,1.2C243.8,127.3,243.2,128.2,243.4,129.9z"
                />
              </defs>
              <clipPath id="SVGID_26_">
                <use xlink:href="#SVGID_25_" style="overflow: visible" />
              </clipPath>
              <path
                class="st14 l16"
                d="M219.8,113.8c5.1,3.3,12.8,9.4,18.1,15"
              />
            </g>
          </g>
          <g id="C">
            <g>
              <defs>
                <path
                  id="SVGID_27_"
                  d="M330.4,38.5c0-0.4-0.2-0.7-0.6-0.7c-0.6,2.7-4.8,1.2-5.9,3.5c-1-0.1-1.8,0-2.7,0.4c0,0.3,0,0.4,0,0.6
					c-0.3,0.3-0.7,0.1-1.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.6,0-0.7,0.3-1,0.7c-0.4,0.2-1.3-0.2-1.8,0c0,0.2,0,0.6,0,0.9
					c-0.4,0.4-1.3,0.2-2,0.2c-0.5,1.2-1.2-0.1-1.5,1c-0.2,0.1-0.5,0.2-0.7,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.3,0-0.4,0
					c-0.3,0.8-1,0.5-1.6,0.5c-0.8,1.5-1.3-0.1-1.7,1.2c-0.8,0.2-1.4-0.4-1.7,0.6c-0.3,0-0.5,0-0.8-0.1c-0.3,0.6-1.1,0.6-1.3,1.2
					c-0.7,0.3-1.7-0.6-1.9,0.4c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.4-0.5,0.8-0.7,1.1c-1.3-0.4-1.5,0.3-2.1,1.2c-0.3,0-0.5,0-0.8-0.1
					c-0.3,0.6-0.6,0.7-1.4,0.6c-0.3,0.5-0.8,1-0.9,1.6c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.5-0.7,1.1-1,1.6c-0.7-0.1-0.6,0.5-1.2,0.7
					c-0.1-0.2-0.1-0.2-0.3-0.2c-2.9,2.3-4.6,5.8-6.7,8.9c0.5,1.8,0.1,2.5-0.9,3.9c0.2,0.2,0.4,0.2,0.5,0.6c-0.2,0.3-0.4,0.5-0.7,0.8
					c-0.2,0.5,0.3,0.9-0.3,1c-0.5,0.1-1.9,0.3-2.1-0.5c-1.9,0.4-1.9-1.3-3.5-1.9c-0.1-0.9-0.8-1-1.1-1.9C279,68,279,68,279,67.8
					c-2.2-2.6-10.8-16.9-2.3-15.3c0.8,0.6,1.9,0.9,2.2-0.2c0.7-0.2,1.9,0.6,2.2-0.6c2.3,0.3,4.1-0.3,6-0.6c0.4-1.1,1.6-0.1,2-1.2
					c1.8,0.3,3.2-1.2,5-1.4c0.9-0.6,2.1-0.7,2.7-1.6c1,0.1,2.4,0.1,3.1-0.4c-0.6-1.4,1.6-0.4,2.2-0.7c0.8-1.5,1.1,0.2,1.7-1.2
					c0.3,0,0.5,0,0.8,0.1c0.4-1.2,1.7-0.1,2-1.1c0.4-0.3,0.7,0.1,0.7-0.6c-0.2-0.1-0.3-0.2-0.6-0.2c-4.2,1.1-0.9-2-5.5-3.3
					c-0.1-1-0.5-1-0.7-1.8c-6.1-2.9-9.9-8.9-13.1-14.4c0.8-1.4,1.8-2.3,2.2-3.9c0.6-0.2,2.6,0.9,3-0.4c0.9-0.1,1.3,0.3,1.2-0.9
					c0.4-0.1,0.7-0.3,0.9-0.7c0.4,0,0.9,0,1.3,0c0,0,0,0.1,0,0.1c0.6,0.3,0.8,1.1,1.6,1.1c1.8,1.5,1.3-1.3,4,2.5
					c2.3,2,5.6,4.2,6.6,7.2c0.6,0.4,0.4,1.2,1,1.6c0,1.8,1.2,3.6,0.1,5.4c0.6,2,0.6,4.8,0.9,6.8c0.2,0.2,0.3,0.4,0.6,0.5
					c0.8-0.7,2.5-0.4,3-1.5c0.5,0.1,0.9,0.2,1.1-0.5c0.6,0.1,1.1,0,1.4-0.6c0.5,0,0.6-0.2,0.7-0.6c0.3,0,0.6,0,0.9,0
					c0.4-0.2,0.1-0.6,0.9-0.7c0.7-0.1,2.4,0.4,2.8-0.5c0.8,0.1,1.1-0.5,1.8-0.6c0.1-0.2,0.2-0.4,0.4-0.6c0.1,0,0.3,0,0.4,0
					c0.1-0.2,0.3-0.4,0.4-0.6c1.2,0,2.9,0.4,3.8-0.5c0.7,0.1,1.5,0,2.2,0c0.4,0.6,0.9,0.6,1.7,0.5C333.7,36.8,333.8,37.2,330.4,38.5
					z"
                />
              </defs>
              <clipPath id="SVGID_28_">
                <use xlink:href="#SVGID_27_" style="overflow: visible" />
              </clipPath>
              <path
                class="st15 l17"
                d="M294.6,24.9c6.8,4.8,11.8,9.4,11.8,9.4"
              />
              <path
                class="st15 l18"
                d="M275.5,58.1c0,0,9.2,12.2,12.5-1.2c2.3-9.2,38.4-21,45.2-22.2"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_29_"
                  d="M275.4,90.2c1.3-0.9,3-3.9,4.5-4.4c0.5-0.5,0.5-1.6,1.3-1.8c0.6,0.5,0.9-0.3,1.1-0.7
					c0.4-0.3,0.9-0.4,1.1-0.9c1.8,0.4,2.2-1.5,3.2-2.9c0.3,0,0.5,0,0.8,0.1c0.1-0.2,0.2-0.4,0.3-0.6c0.2,0,0.3,0,0.5,0
					c0.6-0.5,0.7-1.8,1.7-1.9c0.1-0.1,0.1-0.3,0.2-0.4c1.4-0.1,0.9,0.2,1.1-1.1c1.3,0,2-1.4,2.7-2.3c0.2,0,0.4-0.1,0.6-0.1
					c8.9-9.4,20.1-17.1,29.9-25.9c-0.1-0.5,0-0.7,0.6-0.7c1.3-1.8,2.2-4,2.5-6.1c0.9,0,0.6-0.5,1.1-0.7c1.3,0.9,1.5-2.7,3.8-3.3
					c0.7,0.3,0.7-0.8,1.4-0.6c0.3-0.5,0.6-1,0.9-1.5c-0.1-0.3-0.1-0.7-0.2-1c0.3-0.4,0.5-0.7,0.8-1.1c0-0.2,0-0.5,0-0.7
					c0.7-0.6,0.6-1.8,1.2-2.3c0.6,0,1,0,1.2-0.7c0.5,0,1.9-0.2,2.1,0c0.5,0.6,0.9,0.7,1.6,0.5c1.7,3.7,6.1,6.4,6.2,10.8
					c1.1,1.4,2.5,3.3,1.9,5.2c0.7,6.3-3.5,5.6-7.7,6.8c-1.7-0.3-3.4,0.6-4.7,1.3c-0.4,1.3-1.8,1.1-2.8,1.1c-1,0.8-2.2,1.2-3.4,1.7
					c-6.4,3.8-15,6.2-19.8,12.7c-5.7,2.5-9.7,8.4-16.1,9.3c-1.9,0.9-0.4,1.9-3.2,1.4c-0.4,0.6-0.6,1.2-1.3,1.3
					c-0.1,0.4-0.4,0.5-0.8,0.5c-2,2.4-4.3,4.7-7.4,5.5c-0.1-0.2-0.2-0.7-0.4-1c-0.5,0.9-1.9,1.1-2.2,2.2c-0.3,0.1-0.7,0-0.7,0.4
					c-0.3,0.1-0.5,0.1-0.8,0.2c0,0.1,0,0.2,0,0.4L275.4,90.2z"
                />
              </defs>
              <clipPath id="SVGID_30_">
                <use xlink:href="#SVGID_29_" style="overflow: visible" />
              </clipPath>
              <path
                class="st16 l19"
                d="M341.8,36.3c-0.8,23.9,5.7-17-58.9,54.6"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_31_"
                  d="M279,89.2c1.1-0.2,1.2,1.7,2.5,1.6c0.9-0.4,2.2,0.4,2.7,1.1c1.1-0.7,0.8,0.5,25.4,7
					c1.4,0,2.6,1.4,3.7,0.9c0.5,0.2,0.2,0.6,1.1,0.6c0.5-0.3,1,0.1,1.3,0.5c2.4,0.2,4.4,1.3,6.8,2.2c3.7,0.8,7.7,0.8,11.7,1
					c14.6,0.9,29.5,0.5,44.1-1c0.7-0.2,1.9-0.2,2.7-0.2c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c-0.7,1.2-1.2-0.4-1.9,1.5
					c-1,0.3-1.7,1.1-2.8,1.1c-1.2,0.5-2,1.5-3.4,1.2c-1,0.9-1.7,2.3-3.2,2.3c-0.4,0.6-1.1,0.3-1.6,0.4c-1.3,2.3-0.6,2-3.5,2.4
					c-0.3,1.5,0,3.1-0.9,4.3c0.7,2.6-0.9,2.6-1.9,4.4c-1.1,1.2-2.8,1-4.2,1c-1.4-1.4-4.3-0.1-6.2-0.5c-0.8-0.1-1.3-0.2-1.6,0.6
					c-0.5,0-0.7,0.2-0.9,0.7c-0.4,0-0.8,0-1.2-0.1c-0.5,0.2-0.1,0.6-1.1,0.6c-0.7,0-1.3,0-2,0c-1.3,1.4-4.5,0.3-6.4,0.7
					c-0.4,0.4-0.7-0.4-0.9-0.5c-0.5,0-1,0-1.4,0c-0.2,0.7-0.9,0.4-1.1,1.1c-9.6-2.8-18.4-8.5-27.7-12.3c-4.8-1.9-9.7-3.2-14.6-4.8
					c-0.7-0.1-1.5-0.2-2.2-0.4c-2.2-0.6-4.6-1.6-6.8-0.6c0-0.1-0.1-0.1-0.1-0.2c-1.6,0.1-3.4,0.6-4.9,1.3c-0.6,0.2-1.5-0.1-1.8,0.6
					c-0.5,0.2-1-0.1-1.2,0.6c-1.6,0.2-3,1-4.4,1.7c-1.6,0.4-1.7-1-3-0.6c-0.4-0.8-0.8-0.9-1.5-1.2c0-0.9-1.1-1.4-1.5-2.2
					c-0.1-0.8-0.6-1.6-0.9-2.3c-0.4-5.7-2.4-10-3-16c1.8-0.9,8.2-1.4,9.5,0.6c0.5,0,0.8-0.1,1.2-0.1c0.2,0.4,0.4,0.6,0.9,0.5
					c0.1-0.1,0.3-0.1,0.4,0C273.2,89.1,272.9,89.5,279,89.2z"
                />
              </defs>
              <clipPath id="SVGID_32_">
                <use xlink:href="#SVGID_31_" style="overflow: visible" />
              </clipPath>
              <path
                class="st17 l20"
                d="M264.9,92.8c8.6,15.7,11-0.4,43,14.4s66.5,1.3,66.5,1.3"
              />
            </g>
          </g>
          <g id="D">
            <g>
              <defs>
                <path
                  id="SVGID_33_"
                  d="M433,51.8c-0.5,1.6,1.7,2.9,2.9,3.5c4.2-1.4,6.9-6.5,12-5.1c0.7,0,0.6-0.3,1-0.5
					c0.7-0.3,2.4,0.2,3.1-0.1c2.6-0.9,5.7-2.9,8.2-1.2c-0.3,1.7-2.6,1.5-2.6,3.4c-2.1,2.9-4.3,6.1-5.3,9.3c-1.3,0.5-1.1,2.9-1.6,4.1
					c-2,4-3.2,7.9-3.7,12.3c-1,3.3-1.3,7.2-2.2,10.7c0,2.4,0,4.7,0,7.1c-0.1,5.3-1.2,11,1.7,15.3c1.5,1.4,3.3,2.6,4,4.5
					c0.4,0.6,1.7,0.5,2.5,0.8c0.6,0.9,2.8,0.5,4,0.5c0.9-0.8,2-1.6,2.7-2.6c1.4,0,2.8,0,4.2,0c0.6,0.2,1.4,1,2.2,0.5
					c1.2-0.1,1.5-1.3,2.3-1.8c0.8-0.5,1.8-0.6,2.4-1.3c0.8,0.1,0.9,0.4,1.3,0.7c0.2,2.3-0.1,5.5-2.8,4.9c-2.6,3.6-8.7,0.3-12.3,2.1
					c0.5,1.6,2.7,2.3,4.2,1.9c0.2,0.2,0.4,0.4,0.5,0.5c1,0.1,2.3-0.4,2.8,0.7c4.8,0.6,10.7,4,15.3,1.5c1-0.7,0.9-2.4,2.3-3
					c0.6-2.5,2.1-3.3,3.3-5.7c1.3-8.1,0.6-16.8-0.1-24.9c-0.1-12,2.1-24.5-1.7-35.7c-2.2-2.7-4-2.1-7.1-2.1c-1-1.2-2.5,0.2-3.6-0.7
					c0.1-0.6,0.4-1.3,0.8-1.6c0.1,0,0.1,0,0.2,0c1.6-3.2,5.5-3.5,8.5-5.1c2.8-0.1,4.8,2.1,6.6-0.7c4.4,0.3,7.8-0.2,7.1-4.4
					c-1.4-3.6-4.8-3.3-7.7-5.3c-8.3-1.7-16.4,3.1-24.6,4.3c-2,1.4-4.3,1-6.4-0.1c0.3-1,0.7-1.8,1.8-1.7c0.9-0.4,1.6-1.2,2.6-1
					c3.6-2.2,8.5-3.3,10.9-7.1c0.4-0.2,0.6,0.2,1.1,0c2.3-1.8,5.6-0.6,7.6-3.2c5.1-0.6,9.6-5,12-9.3c2.6-2.2,1.6-6.3,2.6-8.9
					c0.3-0.6,1.1-0.5,1.3-1.1c0.3-0.8-1.6-3.4-2.1-3.6c-0.6-0.2-1.3,0.3-1.7-0.4c-1.1,0.3-2-0.8-2.8-1.2c-0.7,0-1.2,0.2-1.6-0.5
					c-1.5-0.6-1.9,1.1-3.3,0.6c-15.7-5.6-20.2,11.1-24.4,7.5c-3.6-2.2-6.5-3.4-10.7-3c-0.5-0.2-1.7-1.1-2.6-0.5
					c-1.8-0.1-1.8,1.3-1.5,2.7c-1.7,7.6-9.8,13-7.9,21.8c2.6,4.6,5.9,4.2,10.3,3.4c1.5-1.4,4.7-0.8,5.3-2.7c0,0,0.1,0,0.1,0
					c0-0.7,0-1.4,0-2.1c0.7-0.7,1.6-1.2,1.7-2.2c0.5-0.4,0.9-0.7,1-1.4c1.1-0.8,2-2.1,1.3-3.6c0.9-1.4,1.9-0.4,2.8-2.5
					c-0.1-4.5,5.2-5.4,8.9-6.6c1.5-0.8,3.8-2.1,5.3-1c-0.8,1.1-0.8,2.5-2,3.3c-0.9,1.4-1,3.2-2.6,4c-1,2.6-4,3.1-4.5,5.4
					c-0.5,2-14.6,12.8-22.8,17.7c-5.1-1.2-7.2,3.1-10.1,6.3c0.3,1.3-0.8,2-1.2,3.2C432.5,51.2,433,51.6,433,51.8z M461.3,66.4
					c3,0,7.6,0.1,9.8-2c4.8-0.6,3.6,3.7,4.1,7.1c1.2,8.6,2,20,0.7,29.2c-0.2,3.9,0.7,6.2-4.5,5.9c-3.8-1.9-8.8,0.1-11.9-2.6
					c0.8-7.2,11.1-6.1,11.9-13.1c-0.5-0.5-0.8-1.3-1.3-1.8c-0.6,0-0.9-0.3-1.3-0.6c-1.7,0.3-3-0.3-4.3-1.4c0-2.1,2.6-4,3.8-5.1
					c0-0.8-0.1-1.5-0.5-1.8c0.3-1.6,2-2,2.5-3.3c0,0,0.1-1,0.1-1.5c-3.6-1.3-5.1-3.7-9-1C457.1,74.9,457.9,66.9,461.3,66.4z"
                />
              </defs>
              <clipPath id="SVGID_34_">
                <use xlink:href="#SVGID_33_" style="overflow: visible" />
              </clipPath>
              <path
                class="st18 l21"
                d="M453.7,11.3c-1.8,7.4-10.8,20.7-5.1,13.6c8.9-11.1,21.3-30.4,40.1-20.3c3.6,13.7-37.1,27.6-44,27.7
				c-10,0.1-26.7,23.6-12.3,15.8c14.4-7.8,47.3-16.5,59.4-9.4c-13.7-7.4-59.4,17.4-40.5,71.6"
              />
              <path
                class="st18 l22"
                d="M457,59.4c11-2.2,27.2-7.9,26.6,12.8c-0.4,14.2,9.1,58-22.2,53.7"
              />
              <path
                class="st19 l23"
                d="M454.9,76.4c7.8,0.8,7.1,0.4,10.8,0.8c3.7,0.4-9.1,8.2-8.7,10.8s11.5,3.6,11,4.8c-0.5,1.2-23.3,16-8,16.3
				c8.6,0.2,16.6,0,16.6,0"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_35_"
                  d="M393.6,43.3c2.1,2.9,5,6.6,8.8,7.1l0.6,0.6l2.8-0.2c1.8-1.8,2.7-3.8,4-6.1c0,0,0-3.3,0-3.3
					c-0.4-1.1-1.6-3.1-2.9-3.3c-0.8-2.5-2.9-3.7-3.4-6.7c0.2-1.1-0.3-2.2-0.9-3.1c-0.4-0.1-1-0.1-1.4-0.1c-0.4-0.9-2-0.6-2.8-0.5
					c-0.9-1-1.7-2-3.7-1.8c-0.4,0.6-1.1,0.8-1.8,0.7c-0.2,1.4-1.4,2.6-1.4,4c-0.5,0.4-0.7,1.2-0.5,1.9c-0.6,0.7-1,1.6-1,2.5
					c-0.4,0.9-1.1,2-0.7,3.3c0.4,0.8,1.8,4.5,3.5,4.3C393.1,42.8,393.4,43.1,393.6,43.3z"
                />
              </defs>
              <clipPath id="SVGID_36_">
                <use xlink:href="#SVGID_35_" style="overflow: visible" />
              </clipPath>
              <path class="st20 l24" d="M395.6,33.7c3.1,4,7.1,11.4,7.3,11.3" />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_37_"
                  d="M535.9,129.7c-1.1-2.1-2.1-1.2-3.2-2.2c-0.9-0.6-1.9-1.1-2.9-1.1c-0.2-0.2-0.6-0.5-0.9-0.6
					c-6.8-1-8.6,0.7-10.6,0.7c-14.4-0.1-35.5,5.7-61,0.4c-17-3.6-30.2-4.6-39.5-7.3c1-0.7,1.5-1.9,2.5-2.4c0.6-3.8-1.6-6.2-4.5-7.4
					c-1.8-1.9-4.4-4.4-5.9-6.5c-0.3-0.9-1.4-2.6-2.2-3.2c-0.3-1.9,0.7-4.1-0.4-7.3c4.1-2.4,2.3-7.2,2.7-11.3c-2-2.1-4.2-4.9-4.5-7.8
					c-1.3-2.1-4.1-4.3-6.7-3.6c-2.9,0.6-3.2-2-6.3,0.5c0.2,1.1,1,1.9,1.7,2.3c0.2,0.5,0.5,1.7,1.2,1.8c-0.1,0.5,0.2,1.4,0.7,1.8
					c0,0.2,0.1,1.1,0.2,1.2c1.1,1.2-0.1,2.9,0.4,4.3c1.4,5.7-1.6,9.8-1.6,15.7c-1.2,12.6,19.3,14.1,10.5,20.3c-2.6,0-5-0.1-6.8,1.2
					c0.6,3-0.5,4.6-3,6.2l-0.4,1.2c3.6,3.9,3.5,5.2,8.9,2.8c1.1,0.3,2.7-0.4,2.8-1.5c0.7-0.4,2.1-1.3,3.1-1.1l0.6-0.6
					c0.9-0.1,3.8,0.3,4.5-0.6c9.9,0.2,20.3,1.9,29.7,4.4c1.1,1.1,2,3.1,3.6,2.7c2.4,1.2,5.4,1.6,8.1,1.9c10,2.4,20.6,4.8,31.2,4.3
					c5.5,1.3,10.1-2.3,15.2-2.4c3.9-2.3,9.8,0,13.9-1.4c2.3-0.1,4.9-1.8,7.1-1.8c0.8,0.6,2.5,0.1,3.6-0.1c2.5,0.1,5.5-0.8,7.8-1.6
					C534.7,130.4,536.3,130.7,535.9,129.7z"
                />
              </defs>
              <clipPath id="SVGID_38_">
                <use xlink:href="#SVGID_37_" style="overflow: visible" />
              </clipPath>
              <path
                class="st21 l25"
                d="M397.3,70.5c17,25.2-6.6,22.9,8.4,36.1c23.2,20.5,0.9,16.3-4.9,18.2c9.6-2.1,82.4,18.2,130,4.7"
              />
            </g>
          </g>
        </g>
      </svg>

      <div
        class="i3"
        ref="en"
        data-aos="fade"
        data-aos-duration="2000"
        data-aos-delay="600"
      >
        <span>Z</span>
        <span>h</span>
        <span>o</span>
        <span>n</span>
        <span>g</span>
        <span>s</span>
        <span>h</span>
        <span>a</span>
        <span>n</span>
        <span>&ensp;</span>
        <span>A</span>
        <span>r</span>
        <span>t</span>
        <span>&ensp;</span>
        <span>M</span>
        <span>a</span>
        <span>n</span>
        <span>s</span>
        <span>i</span>
        <span>o</span>
        <span>n</span>
      </div>

      <div class="i4 view-pc" data-aos="fade-up" data-aos-delay="1200">
        61-89坪<span v-if="!isMobile">│</span
        ><span else>&nbsp;</span>中山北路首排
        <template>
          <span v-if="!isMobile">│</span>
          <br v-else />
        </template>
        香港P&T<span v-if="!isMobile">│</span
        ><span else>&nbsp;</span>23層樹海地標
      </div>

      <div class="i4" data-aos="fade-up" data-aos-delay="1200" v-if="isMobile">
        61-89坪 中山北路首排
      </div>
      <div class="i5" data-aos="fade-up" data-aos-delay="1200" v-if="isMobile">
        香港P&T 23層樹海地標
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/style/function.scss";
/* 螢幕尺寸標準 */
.section1 {
  min-height: 100vh;
  background-color: rgba($color: #231815, $alpha: 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../pjr/s1/bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  position: relative;

  .logo {
    position: relative;
    width: calc(536 * 100vw / 1920);
    top: calc(50% + (280 - 540) * 100vw / 1920);
    margin: 0 auto calc(105 * 100vw / 1920) auto;
    display: block;
  }
  .st0 {
    clip-path: url(#SVGID_2_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st1 {
    clip-path: url(#SVGID_4_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st2 {
    clip-path: url(#SVGID_6_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st3 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 8.7;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st4 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st5 {
    clip-path: url(#SVGID_8_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st6 {
    clip-path: url(#SVGID_10_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 12;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st7 {
    clip-path: url(#SVGID_12_);
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #ffffff;
    stroke-width: 14;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st8 {
    clip-path: url(#SVGID_14_);
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st9 {
    clip-path: url(#SVGID_16_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st10 {
    clip-path: url(#SVGID_18_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st11 {
    clip-path: url(#SVGID_20_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 17;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st12 {
    clip-path: url(#SVGID_22_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st13 {
    clip-path: url(#SVGID_24_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st14 {
    clip-path: url(#SVGID_26_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 17;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st15 {
    clip-path: url(#SVGID_28_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st16 {
    clip-path: url(#SVGID_30_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st17 {
    clip-path: url(#SVGID_32_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 21;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st18 {
    clip-path: url(#SVGID_34_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 18;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st19 {
    clip-path: url(#SVGID_34_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 11;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st20 {
    clip-path: url(#SVGID_36_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 19;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st21 {
    clip-path: url(#SVGID_38_);
    fill: none;
    stroke: #ffffff;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
  }
  .st22 {
    fill: #f3b2c1;
    animation: op 0.3s 8s linear reverse both;
  }
.l01	{stroke-dasharray:	170	;stroke-dashoffset:	170	;animation: letterDraw	0.17s 	0s linear forwards;}
.l02	{stroke-dasharray:	135	;stroke-dashoffset:	135	;animation: letterDraw	0.135s 	0.17s linear forwards;}
.l03	{stroke-dasharray:	135	;stroke-dashoffset:	135	;animation: letterDraw	0.135s 	0.305s linear forwards;}
.l04	{stroke-dasharray:	60	;stroke-dashoffset:	60	;animation: letterDraw	0.06s 	0.44s linear forwards;}
.l05	{stroke-dasharray:	47	;stroke-dashoffset:	47	;animation: letterDraw	0.047s 	0.5s linear forwards;}
.l06	{stroke-dasharray:	150	;stroke-dashoffset:	150	;animation: letterDraw	0.15s 	0.547s linear forwards;}
.l07	{stroke-dasharray:	50	;stroke-dashoffset:	50	;animation: letterDraw	0.05s 	0.697s linear forwards;}
.l08	{stroke-dasharray:	165	;stroke-dashoffset:	165	;animation: letterDraw	0.165s 	0.747s linear forwards;}
.l09	{stroke-dasharray:	92	;stroke-dashoffset:	92	;animation: letterDraw	0.092s 	0.912s linear forwards;}
.l10	{stroke-dasharray:	36	;stroke-dashoffset:	36	;animation: letterDraw	0.036s 	1.004s linear forwards;}
.l11	{stroke-dasharray:	48	;stroke-dashoffset:	48	;animation: letterDraw	0.048s 	1.04s linear forwards;}
.l12	{stroke-dasharray:	260	;stroke-dashoffset:	260	;animation: letterDraw	0.26s 	1.088s linear forwards;}
.l13	{stroke-dasharray:	67	;stroke-dashoffset:	67	;animation: letterDraw	0.067s 	1.348s linear forwards;}
.l14	{stroke-dasharray:	110	;stroke-dashoffset:	110	;animation: letterDraw	0.11s 	1.415s linear forwards;}
.l15	{stroke-dasharray:	26	;stroke-dashoffset:	26	;animation: letterDraw	0.026s 	1.525s linear forwards;}
.l16	{stroke-dasharray:	28	;stroke-dashoffset:	28	;animation: letterDraw	0.028s 	1.551s linear forwards;}
.l17	{stroke-dasharray:	16	;stroke-dashoffset:	16	;animation: letterDraw	0.016s 	1.579s linear forwards;}
.l18	{stroke-dasharray:	72	;stroke-dashoffset:	72	;animation: letterDraw	0.072s 	1.595s linear forwards;}
.l19	{stroke-dasharray:	86	;stroke-dashoffset:	86	;animation: letterDraw	0.086s 	1.667s linear forwards;}
.l20	{stroke-dasharray:	120	;stroke-dashoffset:	120	;animation: letterDraw	0.12s 	1.753s linear forwards;}
.l21	{stroke-dasharray:	320	;stroke-dashoffset:	320	;animation: letterDraw	0.32s 	1.873s linear forwards;}
.l22	{stroke-dasharray:	107	;stroke-dashoffset:	107	;animation: letterDraw	0.107s 	2.193s linear forwards;}
.l23	{stroke-dasharray:	82	;stroke-dashoffset:	82	;animation: letterDraw	0.082s 	2.3s linear forwards;}
.l24	{stroke-dasharray:	17	;stroke-dashoffset:	17	;animation: letterDraw	0.017s 	2.382s linear forwards;}
.l25	{stroke-dasharray:	210	;stroke-dashoffset:	210	;animation: letterDraw	0.21s 	2.399s linear forwards;}


  .t {
    top: calc(50% + (280 - 540) * 100vw / 1920);
    position: relative;
    width: calc(1275 * 100vw / 1920);
    margin: auto;
    display: block;
    animation: op 0.3s 8.2s linear reverse both;
  }

  @keyframes letterDraw {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes op {
    to {
      opacity: 0;
    }
  }

  .mo {
    display: none;
  }

  .intro {
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    .i1 {
      font-size: size(65.5);
      font-weight: 500;
      letter-spacing: size(5);
      font-family: "Noto Serif TC", Noto Sans TC, serif;
      white-space: nowrap;
    }
    .i2 {
      margin-top: size(40);
      width: size(454);
      img {
        width: 100%;
      }
    }
    .i3 {
      margin-top: size(5);
      font-family: "Noto Serif TC";
      font-size: size(38);
      font-weight: 100;
      span {
        &.flicking {
          animation: flicking 2s;
        }
      }

      @keyframes flicking {
        from {
          opacity: 1;
        }
        to {
          opacity: 0.2;
        }
      }
    }
    .i4 {
      margin-top: size(40);
      font-size: size(36);
      letter-spacing: size(5);
      font-family: "Noto Serif TC", Noto Sans TC, serif;
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .section1 {
    min-height: size-m(667);
    background-color: rgba($color: #231815, $alpha: 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../pjr/s1/bg_mo.jpg");
    background-position: 50%;
    background-size: cover;
    background-attachment: inherit;

    .logo {
      width: calc(197 * 100vw / 375);
      top: calc(50% + (299 - 540) * 100vw / 375);
      margin: 0 auto calc(45 * 100vw / 375) auto;
    }
    .mo .st0 {
      clip-path: url(#SVGID_2_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st1 {
      clip-path: url(#SVGID_4_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st2 {
      clip-path: url(#SVGID_6_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st3 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 8.7;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st4 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st5 {
      clip-path: url(#SVGID_8_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st6 {
      clip-path: url(#SVGID_10_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 12;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st7 {
      clip-path: url(#SVGID_12_m);
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: none;
      stroke: #ffffff;
      stroke-width: 14;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st8 {
      clip-path: url(#SVGID_14_m);
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st9 {
      clip-path: url(#SVGID_16_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st10 {
      clip-path: url(#SVGID_18_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st11 {
      clip-path: url(#SVGID_20_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 17;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st12 {
      clip-path: url(#SVGID_22_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st13 {
      clip-path: url(#SVGID_24_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st14 {
      clip-path: url(#SVGID_26_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 17;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st15 {
      clip-path: url(#SVGID_28_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st16 {
      clip-path: url(#SVGID_30_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st17 {
      clip-path: url(#SVGID_32_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 21;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st18 {
      clip-path: url(#SVGID_34_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 18;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st19 {
      clip-path: url(#SVGID_34_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 11;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st20 {
      clip-path: url(#SVGID_36_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 19;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
    .mo .st21 {
      clip-path: url(#SVGID_38_m);
      fill: none;
      stroke: #ffffff;
      stroke-width: 15;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .t {
      top: calc(50% + (299 - 540) * 100vw / 375);
      width: calc(260 * 100vw / 375);
    }
    .mo {
      display: block;
    }
    .pc {
      display: none;
    }

    .view-pc {
      display: none;
    }
    .intro {
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      .i1 {
        font-size: size-m(30);
        font-weight: 500;
        letter-spacing: size(5);
        font-weight: 600;
      }
      .i2 {
        margin-top: size-m(40);
        width: size-m(150);
        img {
          width: 100%;
        }
      }
      .i3 {
        margin-top: size-m(5);
        font-family: "Noto Serif TC";
        font-size: 12px;
        font-weight: 100;
        transform: scale(0.8) !important;
        span {
          &.flicking {
            animation: flicking 2s;
          }
        }

        @keyframes flicking {
          from {
            opacity: 1;
          }
          to {
            opacity: 0.2;
          }
        }
      }
      .i4 {
        margin-top: size-m(40);
        font-size: size-m(23);
        line-height: 1.5;
        letter-spacing: size-m(2);
        text-align: center;
        font-weight: 600;
      }
      .i5 {
        margin-top: size-m(3);
        font-size: size-m(21.5);
        line-height: 1.5;
        letter-spacing: size-m(2);
        text-align: center;
        font-family: "Noto Serif TC", Noto Sans TC, serif;
        font-weight: 600;
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import { isMobile } from "@/utils";

export default {
  name: "section1",

  data() {
    return {
      isMobile,
    };
  },

  methods: {
    getRandom(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    setAnimateToEn() {
      const target = this.$refs.en.querySelectorAll("span");

      let random = this.getRandom(target, 5);

      random.forEach((element, i) => {
        setTimeout(() => {
          element.classList.remove("flicking");
          element.classList.add("flicking");
        }, i * 500);
      });
    },
  },

  mounted() {
    let interval;
    interval = setInterval(() => {
      this.setAnimateToEn();
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, 3000);
  },

  created() {},
};
</script>
