export default [{
        name: "百年林蔭",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section3",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "富饒巡禮",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section4",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "樹海首席",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section6",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "璞真品牌",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section7",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "璞真建設",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section9",
        svgSrc: "",
        offset: 150,
        mobileOffset: 0
    },
    {
        name: "建築團隊",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section11",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "粉絲專頁",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0,
        link: "https://www.facebook.com/hiyes.tw/"
    }
];